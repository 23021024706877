<template>
    <div class="home" id=""><div id="" class=""><h1 class="" id="">Datenschutzerklärung</h1></div>
    <br/>
    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. 
    Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen 
    Datenschutzvorschriften
    sowie dieser Datenschutzerklärung.<br/>
    <br/>
    Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. 
    Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen)
    erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. 
    Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.<br/>
    <br/>
    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) 
    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
    Dritte ist nicht möglich.<br/>
    </div>
</template>
<script>
</script>
<style>
</style>